
import http from "../../../../../common/services/http";
import { defineComponent, ref, reactive, onMounted, computed } from "vue";
import router from "@/router";
import NgongVegBanner from "@/components/NgongVegBanner.vue";

export default defineComponent({
  components: { NgongVegBanner },
  setup(props) {
    const headers = {
      name: "Cost",
      amount: "Amount"
    };

    const records = ref({
      data: []
    });

    const record = reactive({
      name: "",
      amount: "",
      cost_category_id: 0
    });

    const search = ref("");
    const category = ref({});

    const processing = ref(false);
    const created = ref(false);
    const updated = ref(false);

    const path = computed(() =>
      router.currentRoute.value.path.split("/").pop()
    );

    function fetchRecords(page = 1) {
      processing.value = true;
      http.get(`/api/cost-types?slug=${path.value}`).then((res: any) => {
        const cat = res.data;
        category.value = cat;
        record.cost_category_id = cat.id;

        http
          .get(`/api/costs?cost_category_id=${cat.id}&page=${page}`)
          .then(res => {
            records.value = res.data;
            processing.value = false;
          });
      });
    }

    function searchRecords(e: any) {
      processing.value = true;
      http.get(`/api/costs?s=${search.value}`).then(res => {
        records.value = res.data;
        processing.value = false;
      });
    }

    function createRecord() {
      http
        .post("/api/costs", record)
        .then(res => {
          if (res.data.success) {
            created.value = true;

            record.name = "";
            record.amount = "";
            record.cost_category_id = 0;
          }
        })
        .finally(() => {
          document.getElementById("hideNewForm")?.click();
          fetchRecords();
        });
    }

    function updateRecord(record: any) {
      record._method = "PUT";
      http
        .post(`/api/costs/${record.id}`, record)
        .then(res => {
          if (res.data.success) {
            updated.value = true;
          }
        })
        .finally(() => {
          document.getElementById("hideEditForm")?.click();
          fetchRecords();
        });
    }

    function deleteRecord(id: any) {
      http.post(`/api/costs/${id}`, { _method: "DELETE" }).then(res => {
        fetchRecords();
      });
    }

    onMounted(() => {
      fetchRecords();
    });

    return {
      headers,
      fetchRecords,
      processing,
      records,
      createRecord,
      created,
      record,
      updateRecord,
      updated,
      deleteRecord,
      search,
      searchRecords,
      path,
      category
    };
  }
});
